import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SimplePercentageComponent } from './simple-percentage/simple-percentage.component';
import { PercentageDiscountComponent } from './percentage-discount/percentage-discount.component';
import { PercentageIncreaseComponent } from './percentage-increase/percentage-increase.component';
import { PriceQuantityComponent } from './price-quantity/price-quantity.component';


const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    component: DashboardComponent
  },
  { path: 'simplepercentage', component: SimplePercentageComponent },
  { path: 'discount', component: PercentageDiscountComponent },
  { path: 'increase', component: PercentageIncreaseComponent },
  { path: 'pricequantity', component: PriceQuantityComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
