import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          {
            title: 'Product Price/Amount Comparison',
            cols: 2,
            rows: 1,
            imgSrc: 'https://i.picsum.photos/id/1/800/250.jpg',
            href: 'pricequantity',
            features:[
              'Which product is cheaper for different quantity, weight, amount?',
              'Is 150g per 5$ cheaper than 100g per 4$?',
              'How much cheaper is Product 1 than Product 2?'
            ]
          }, {
            title: 'Calculate Simple Percentages',
            cols: 2,
            rows: 1,
            imgSrc: 'https://i.picsum.photos/id/2/800/250.jpg',
            href: 'simplepercentage',
            features:[
              'How much is 20% of 250?',
              'What\'s the percentage of 50 in 250?',
              '20% of which number is 50?'
            ]
          }, {
            title: 'Calculate Discounts by Percentage',
            cols: 2,
            rows: 1,
            imgSrc: 'https://i.picsum.photos/id/3/800/250.jpg',
            href: 'discount',
            features:[
              'What\'s the 20% discount of 250$?',
              'What\'s the discount percentage of price from 250$ to 200$?',
              'How much am I saving after 25% discount of 250$?',
              'What is the price before 25% discount if the discounted price is 200$?'
            ]
          }, {
            title: 'Calculate Increases by Percentage',
            cols: 2,
            rows: 1,
            imgSrc: 'https://i.picsum.photos/id/4/800/250.jpg',
            href: 'increase',
            features:[
              'What\'s the 20% increase of 250$?',
              'What\'s the increase percentage of price from 200$ to 250$?',
              'How much am I increasing after 25% increase of 200$?',
              'What is the price before 25% increase if the increased price is 250$?'
            ]
          }
        ];
      }

      return [
        {
          title: 'Product Price/Amount Comparison',
          cols: 1,
          rows: 1,
          imgSrc: 'https://i.picsum.photos/id/1/800/250.jpg',
          href: 'pricequantity',
          features:[
            'Which product is cheaper for different quantity, weight, amount?',
            'Is 150g per 5$ cheaper than 100g per 4$?',
            'How much cheaper is Product 1 than Product 2?'
          ]
        }, {
          title: 'Calculate Simple Percentages',
          cols: 1,
          rows: 1,
          imgSrc: 'https://i.picsum.photos/id/4/800/250.jpg',
          href: 'simplepercentage',
          features:[
            'How much is 20% of 250?',
            'What\'s the percentage of 50 in 250?',
            '20% of which number is 50?'
          ]
        }, {
          title: 'Calculate Discounts by Percentage',
          cols: 1,
          rows: 1,
          imgSrc: 'https://i.picsum.photos/id/2/800/250.jpg',
          href: 'discount',
          features:[
            'What\'s the 20% discount of 250$?',
            'What\'s the discount percentage of price from 250$ to 200$?',
            'How much am I saving after 25% discount of 250$?',
            'What is the price before 25% discount if the discounted price is 200$?'
          ]
        }, {
          title: 'Calculate Increases by Percentage',
          cols: 1,
          rows: 1,
          imgSrc: 'https://i.picsum.photos/id/3/800/250.jpg',
          href: 'increase',
          features:[
            'What\'s the 20% increase of 250$?',
            'What\'s the increase percentage of price from 200$ to 250$?',
            'How much am I increasing after 25% increase of 200$?',
            'What is the price before 25% increase if the increased price is 250$?'
          ]
        }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) {}
}
