import { Component } from '@angular/core';
import { RoundNumberPipe } from '../round-number.pipe';

@Component({
  selector: 'app-percentage-increase',
  templateUrl: './percentage-increase.component.html',
  styleUrls: ['./percentage-increase.component.css']
})
export class PercentageIncreaseComponent {
  beforeIncrease = '';
  increase = '';
  afterIncrease = '';
  increaseAmount = '';
  hasSolution = false;
  formulaImgName1 = '';
  formulaImgName2 = '';
  formulaCalculation1 = '';
  formulaCalculation2 = '';

  inputs: string[] = ['beforeIncrease', 'increase'];
  allInputs: string[] = ['beforeIncrease', 'increase', 'afterIncrease', 'increaseAmount'];

  constructor(private roundNumberPipe: RoundNumberPipe) {}

  onInputChange(inputName: string) {
    this.updateInputArray(inputName);

    // const calculatedInput = this.getCalculatedField();
    this.updateCalculatedFields();
  }

  // Field that is being calculated, so will show the caluclated result
  isCalculatedField(inputName: string, showResults: boolean) {
    const index = this.inputs.indexOf(inputName, 0);
    if (showResults && (!this.beforeIncrease || !this.increase || !this.afterIncrease || !this.increaseAmount)) {
      return false;
    }
    if (index > -1) {
      return false;
    }
    return true;
  }

  areCalculatedFields(firstInput: string, secondInput: string, showResults: boolean) {
    return this.isCalculatedField(firstInput, showResults) && this.isCalculatedField(secondInput, showResults);
  }

  // Returns fields that are inputs for calculation
  getCalculatedField(): string[] {
    return this.allInputs.filter(input => this.inputs.indexOf(input) < 0);
  }

  // Field that is and input for calculation
  // isCalculatedInput(inputName: string) {
  //   return this.getCalculatedField().indexOf(inputName, 0) > -1;
  // }

  // areCalculatedInputs(firstInput: string, secondInput: string) {
  //   return this.isCalculatedInput(firstInput) && this.isCalculatedInput(secondInput);
  // }

  updateInputArray(inputName: string){
    const index = this.inputs.indexOf(inputName, 0);
    if (index > -1) {
      this.inputs.splice(index, 1);
    }
    this.inputs = [
      inputName,
      ...this.inputs
    ];
    if (this.inputs.length > 2) {
      this.inputs.splice(-1, 1);
    }
  }

  updateCalculatedFields() {
    if (this.areCalculatedFields('afterIncrease', 'increaseAmount', false) &&
      this.beforeIncrease !== '' && this.increase !== '') {
      this.hasSolution = true;

      this.afterIncrease = this.roundNumberPipe.transform(+this.beforeIncrease + (+this.beforeIncrease * +this.increase / 100));
      this.increaseAmount = this.roundNumberPipe.transform(+this.beforeIncrease * +this.increase / 100);

      this.formulaImgName1 = '16';
      this.formulaImgName2 = '5';

      this.formulaCalculation1 =
        ' = (' + this.beforeIncrease + ' + (' + this.beforeIncrease + ' * ' + this.increase + ') : 100) = ' + this.afterIncrease;

      this.formulaCalculation2 =
        ' = (' + this.beforeIncrease + ' * ' + this.increase + ') : 100 = ' + this.increaseAmount;

    } else
    if (this.areCalculatedFields('increase', 'increaseAmount', false) &&
      this.beforeIncrease !== '' && this.afterIncrease !== '') {
      this.hasSolution = true;

      this.increase = this.roundNumberPipe.transform(((+this.afterIncrease - +this.beforeIncrease) * 100) / +this.beforeIncrease);
      this.increaseAmount = this.roundNumberPipe.transform(+this.afterIncrease - +this.beforeIncrease);

      this.formulaImgName1 = '17';
      this.formulaImgName2 = '18';

      this.formulaCalculation1 =
        ' = ( (' + this.afterIncrease + ' - ' + this.beforeIncrease + ') * 100) : ' + this.beforeIncrease + ' = ' + this.increase;

      this.formulaCalculation2 =
      ' = ' + this.afterIncrease + ' - ' + this.beforeIncrease + ' = ' + this.increaseAmount;

    } else
    if (this.areCalculatedFields('increase', 'afterIncrease', false) &&
      this.beforeIncrease !== '' && this.increaseAmount !== '') {
      this.hasSolution = true;

      this.increase = this.roundNumberPipe.transform(+this.increaseAmount * 100 / +this.beforeIncrease);
      this.afterIncrease = this.roundNumberPipe.transform(+this.beforeIncrease + +this.increaseAmount);

      this.formulaImgName1 = '8';
      this.formulaImgName2 = '19';

      this.formulaCalculation1 =
      ' = (' + this.increaseAmount + ' * 100) : ' + this.beforeIncrease + ' = ' + this.increase;

      this.formulaCalculation2 =
      ' = ' + this.beforeIncrease + ' + ' + this.increaseAmount + ' = ' + this.afterIncrease;

    } else
    if (this.areCalculatedFields('beforeIncrease', 'increaseAmount', false) &&
      this.increase !== '' && this.afterIncrease !== '') {
      this.hasSolution = true;

      this.beforeIncrease = this.roundNumberPipe.transform(100 * +this.afterIncrease / (100 + +this.increase));
      this.increaseAmount = this.roundNumberPipe.transform(+this.afterIncrease - (100 * +this.afterIncrease / (100 + +this.increase)));

      this.formulaImgName1 = '20';
      this.formulaImgName2 = '21';

      this.formulaCalculation1 =
      ' = (100 * ' + this.afterIncrease + ') : (100 + ' + this.increase + ') = ' + this.beforeIncrease;

      this.formulaCalculation2 =
      ' = ( ' + this.afterIncrease + ' - (100 * ' + this.afterIncrease + ') : (100 + ' + this.increase + ') )' + ' = ' + this.increaseAmount;

    } else
    if (this.areCalculatedFields('beforeIncrease', 'afterIncrease', false) &&
        this.increase !== '' && this.increaseAmount !== '') {
      this.hasSolution = true;

      this.beforeIncrease = this.roundNumberPipe.transform(+this.increaseAmount * 100 / +this.increase);
      this.afterIncrease = this.roundNumberPipe.transform((+this.increaseAmount * 100 / +this.increase) + +this.increaseAmount);

      this.formulaImgName1 = '12';
      this.formulaImgName2 = '22';

      this.formulaCalculation1 =
      ' = (' + this.increaseAmount + ' * 100) : ' + this.increase + ' = ' + this.beforeIncrease;

      this.formulaCalculation2 =
      ' = ( (' + this.increaseAmount + ' * 100) : ' + this.increase + ') + ' + this.increaseAmount + ' = ' + this.afterIncrease;

    } else
    if (this.areCalculatedFields('beforeIncrease', 'increase', false) &&
        this.afterIncrease !== '' && this.increaseAmount !== '') {
      this.hasSolution = true;

      this.beforeIncrease = this.roundNumberPipe.transform(+this.afterIncrease - +this.increaseAmount);
      this.increase = this.roundNumberPipe.transform(+this.increaseAmount * 100 / (+this.afterIncrease - +this.increaseAmount));

      this.formulaImgName1 = '24';
      this.formulaImgName2 = '23';

      this.formulaCalculation1 =
      ' = ' + this.afterIncrease + ' - ' + this.increaseAmount + ' = ' + this.beforeIncrease;

      this.formulaCalculation2 =
      ' = (' + this.increaseAmount + ' * 100) : (' + this.afterIncrease + ' - ' + this.increaseAmount + ') = ' + this.increase;

    }
  }

  clearInput(input: string) {
    switch (input) {
      case 'beforeIncrease':
        this.beforeIncrease = '';
        break;
      case 'increase':
        this.increase = '';
        break;
      case 'afterIncrease':
        this.afterIncrease = '';
        break;
      case 'increaseAmount':
        this.increaseAmount = '';
        break;
      default:
        break;
    }
    this.hasSolution = false;
  }

  getFieldLabel(input: string): string {
    switch (input) {
      case 'beforeIncrease':
        return 'Before increase';
      case 'increase':
        return 'Increase';
      case 'afterIncrease':
        return 'After increase';
      case 'increaseAmount':
        return 'Increase amount';
      default:
        return '';
    }
  }

}
