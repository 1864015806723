import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundNumber'
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: number, roundPlaces: number = 2): string {
      // return +(Math.round(+value + 'e+' + roundPlaces)  + 'e-' + roundPlaces);
      const mathPow = Math.pow(10, roundPlaces);
      const mathRound = Math.round(value * mathPow) / mathPow;
      const rounded = parseFloat(String(mathRound)).toFixed(roundPlaces);
      return rounded.replace('.00', '');
  }

}
