import { Component } from '@angular/core';
import { RoundNumberPipe } from '../round-number.pipe';

@Component({
  selector: 'app-simple-percentage',
  templateUrl: './simple-percentage.component.html',
  styleUrls: ['./simple-percentage.component.css']
})
export class SimplePercentageComponent {
  percent = '';
  ofAmount = '';
  result = '';
  hasSolution = false;
  formulaImgName = '';
  formulaCalculation = '';

  inputs: string[] = ['percent', 'ofAmount'];
  allInputs: string[] = ['percent', 'ofAmount', 'result'];

  constructor(private roundNumberPipe: RoundNumberPipe) {}

  onInputChange(inputName: string) {
    this.updateInputArray(inputName);

    const calculatedInput = this.getCalculatedField();
    this.updateCalculatedInput(calculatedInput);
  }

  isCalculatedField(inputName: string) {
    const index = this.inputs.indexOf(inputName, 0);
    if (index > -1 || this.percent === '' || this.ofAmount === '' || this.result === '') {
      return false;
    }
    return true;
  }

  getCalculatedField(): string[]{
    return this.allInputs.filter(input => this.inputs.indexOf(input) < 0);
  }

  updateInputArray(inputName: string){
    const index = this.inputs.indexOf(inputName, 0);
    if (index > -1) {
      this.inputs.splice(index, 1);
    }
    this.inputs = [
      inputName,
      ...this.inputs
    ];
    if (this.inputs.length > 2) {
      this.inputs.splice(-1, 1);
    }
  }

  updateCalculatedInput(calculatedInput: string[]){
    if (calculatedInput.indexOf('percent', 0) > -1 && this.result !== '' && this.ofAmount !== '') {
      this.hasSolution = true;
      this.percent = this.roundNumberPipe.transform(+this.result * 100 / +this.ofAmount);
      this.formulaImgName = '3';
      this.formulaCalculation = ' = (' + this.result + ' * 100) : ' + this.ofAmount + ' = ' + this.percent;
    } else
    if (calculatedInput.indexOf('ofAmount', 0) > -1 && this.result !== '' && this.percent !== '') {
      this.hasSolution = true;
      this.ofAmount = this.roundNumberPipe.transform(+this.result * 100 / +this.percent);
      this.formulaImgName = '2';
      this.formulaCalculation = '= (' + this.result + ' * 100) : ' + this.percent + ' = ' + this.ofAmount;
    } else
    if (calculatedInput.indexOf('result', 0) > -1 && this.ofAmount !== '' && this.percent !== '') {
      this.hasSolution = true;
      this.result = this.roundNumberPipe.transform(+this.ofAmount * +this.percent / 100);
      this.formulaImgName = '1';
      this.formulaCalculation = ' = (' + this.ofAmount + ' * ' + this.percent + ') : 100 = ' + this.result;
    } else {
      this.hasSolution = false;
      this.formulaImgName = '';
      this.formulaCalculation = '';
    }
  }

  clearInput(input: string) {
    switch (input) {
      case 'percent':
        this.percent = '';
        break;
      case 'ofAmount':
        this.ofAmount = '';
        break;
        case 'result':
          this.result = '';
          break;
      default:
        break;
    }
    this.hasSolution = false;
  }

}
