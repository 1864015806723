import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { RoundNumberPipe } from '../round-number.pipe';

@Component({
  selector: 'app-price-quantity',
  templateUrl: './price-quantity.component.html',
  styleUrls: ['./price-quantity.component.scss']
})
export class PriceQuantityComponent {

  form = this.formBuilder.group({
    productsArray: this.formBuilder.array([
      this.formBuilder.group({
        price: null,
        quantity: null
      }),
      this.formBuilder.group({
        price: null,
        quantity: null
      })
    ])
  });

  solution = [];

  get products() {
    return this.form.get('productsArray') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private roundNumberPipe: RoundNumberPipe
  ) {}

  onInputChange() {
    if (!this.allInputsPopulated()){
      this.clearSolution();
      return;
    }

    this.solution = this.products.controls.map((x, i)=> {
      return {
        index: i,
        value: x.get('price').value / x.get('quantity').value}
      }
      ).sort((a, b)=> a.value - b.value)
    .reduce((calculated, nextVal) => {
      var cheaperPerc = calculated.length > 0 ? this.getCheaperPercentage(calculated[0].value, nextVal.value): null;

      calculated.push({
        ...nextVal,
        cheaperPerc
      });
      return calculated;
    },[]);
  }

  allInputsPopulated(){
   return !this.products.controls.map(x=> {
      return !!x.get('price').value && !!x.get('quantity').value;
    }).some(y => !y);
  }

  addProduct(){
    this.clearSolution();
    this.products.push(
      this.formBuilder.group({
      price: '',
      quantity: ''
    })
    );
  }

  clearValue(field: string, index: number){
    this.clearSolution();
    this.products.controls[index].get(field).setValue(null);
  }

  removeProduct(index: number){
    if (this.products.controls.length > 2) {
      this.clearSolution();
      this.products.removeAt(index);
    }
  }

  getCheaperPercentage(smallerNo: number, expensiveNo: number){
    return this.roundNumberPipe.transform(((expensiveNo - smallerNo) * 100) / expensiveNo);
  }

  clearSolution(){
    this.solution = [];
  }

}
