import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  constructor(private breakpointObserver: BreakpointObserver) {}
  @ViewChild('drawer', {static: false}) sidenav: MatSidenav;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  menuClick() {
    if (this.sidenav.mode === 'over') {
      this.sidenav.toggle();
    }
  }

  openDialog() {
    this.sidenav.open();
  }

  closeDialog() {
    this.sidenav.close();
  }
}
