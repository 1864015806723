import { Component } from '@angular/core';
import { RoundNumberPipe } from '../round-number.pipe';

@Component({
  selector: 'app-percentage-discount',
  templateUrl: './percentage-discount.component.html',
  styleUrls: ['./percentage-discount.component.css']
})
export class PercentageDiscountComponent {
  beforeDiscount = '';
  discount = '';
  afterDiscount = '';
  discountAmount = '';
  hasSolution = false;
  formulaImgName1 = '';
  formulaImgName2 = '';
  formulaCalculation1 = '';
  formulaCalculation2 = '';

  inputs: string[] = ['beforeDiscount', 'discount'];
  allInputs: string[] = ['beforeDiscount', 'discount', 'afterDiscount', 'discountAmount'];

  constructor(private roundNumberPipe: RoundNumberPipe) {}

  onInputChange(inputName: string) {
    this.updateInputArray(inputName);

    // const calculatedInput = this.getCalculatedField();
    this.updateCalculatedFields();
  }

  // Field that is being calculated, so will show the caluclated result
  isCalculatedField(inputName: string, showResults: boolean) {
    const index = this.inputs.indexOf(inputName, 0);
    if (showResults && (!this.beforeDiscount || !this.discount || !this.afterDiscount || !this.discountAmount)) {
      return false;
    }
    if (index > -1) {
      return false;
    }
    return true;
  }

  areCalculatedFields(firstInput: string, secondInput: string, showResults: boolean) {
    return this.isCalculatedField(firstInput, showResults) && this.isCalculatedField(secondInput, showResults);
  }

  // Returns fields that are inputs for calculation
  getCalculatedField(): string[] {
    return this.allInputs.filter(input => this.inputs.indexOf(input) < 0);
  }

  // Field that is and input for calculation
  // isCalculatedInput(inputName: string) {
  //   return this.getCalculatedField().indexOf(inputName, 0) > -1;
  // }

  // areCalculatedInputs(firstInput: string, secondInput: string) {
  //   return this.isCalculatedInput(firstInput) && this.isCalculatedInput(secondInput);
  // }

  updateInputArray(inputName: string){
    const index = this.inputs.indexOf(inputName, 0);
    if (index > -1) {
      this.inputs.splice(index, 1);
    }
    this.inputs = [
      inputName,
      ...this.inputs
    ];
    if (this.inputs.length > 2) {
      this.inputs.splice(-1, 1);
    }
  }

  updateCalculatedFields() {
    if (this.areCalculatedFields('afterDiscount', 'discountAmount', false) &&
        this.beforeDiscount !== '' && this.discount !== '') {
      this.hasSolution = true;

      this.afterDiscount = this.roundNumberPipe.transform(+this.beforeDiscount - (+this.beforeDiscount * +this.discount / 100));
      this.discountAmount = this.roundNumberPipe.transform(+this.beforeDiscount * +this.discount / 100);

      this.formulaImgName1 = '4';
      this.formulaImgName2 = '5';

      this.formulaCalculation1 =
        ' = (' + this.beforeDiscount + ' - (' + this.beforeDiscount + ' * ' + this.discount + ') : 100) = ' + this.afterDiscount;

      this.formulaCalculation2 =
        ' = (' + this.beforeDiscount + ' * ' + this.discount + ') : 100 = ' + this.discountAmount;

    } else
    if (this.areCalculatedFields('discount', 'discountAmount', false) &&
        this.beforeDiscount !== '' && this.afterDiscount !== '') {
      this.hasSolution = true;

      this.discount = this.roundNumberPipe.transform(((+this.beforeDiscount - +this.afterDiscount) * 100) / +this.beforeDiscount);
      this.discountAmount = this.roundNumberPipe.transform(+this.beforeDiscount - +this.afterDiscount);

      this.formulaImgName1 = '6';
      this.formulaImgName2 = '7';

      this.formulaCalculation1 =
        ' = ( (' + this.beforeDiscount + ' - ' + this.afterDiscount + ') * 100) : ' + this.beforeDiscount + ' = ' + this.discount;

      this.formulaCalculation2 =
      ' = ' + this.beforeDiscount + ' - ' + this.afterDiscount + ' = ' + this.discountAmount;

    } else
    if (this.areCalculatedFields('discount', 'afterDiscount', false) &&
        this.beforeDiscount !== '' && this.discountAmount !== '') {
      this.hasSolution = true;

      this.discount = this.roundNumberPipe.transform(+this.discountAmount * 100 / +this.beforeDiscount);
      this.afterDiscount = this.roundNumberPipe.transform(+this.beforeDiscount - +this.discountAmount);

      this.formulaImgName1 = '8';
      this.formulaImgName2 = '9';

      this.formulaCalculation1 =
      ' = (' + this.discountAmount + ' * 100) : ' + this.beforeDiscount + ' = ' + this.discount;

      this.formulaCalculation2 =
      ' = ' + this.beforeDiscount + ' - ' + this.discountAmount + ' = ' + this.afterDiscount;

    } else
    if (this.areCalculatedFields('beforeDiscount', 'discountAmount', false) &&
        this.discount !== '' && this.afterDiscount !== '') {
      this.hasSolution = true;

      this.beforeDiscount = this.roundNumberPipe.transform(100 * +this.afterDiscount / (100 - +this.discount));
      this.discountAmount = this.roundNumberPipe.transform((100 * +this.afterDiscount / (100 - +this.discount)) - +this.afterDiscount);

      this.formulaImgName1 = '10';
      this.formulaImgName2 = '11';

      this.formulaCalculation1 =
      ' = (100 * ' + this.afterDiscount + ') : (100 - ' + this.discount + ') = ' + this.beforeDiscount;

      this.formulaCalculation2 =
      ' = ( (100 * ' + this.afterDiscount + ') : (100 - ' + this.discount + ') ) - ' + this.afterDiscount + ' = ' + this.discountAmount;

    } else
    if (this.areCalculatedFields('beforeDiscount', 'afterDiscount', false) &&
        this.discount !== '' && this.discountAmount !== '') {
      this.hasSolution = true;

      this.beforeDiscount = this.roundNumberPipe.transform(+this.discountAmount * 100 / +this.discount);
      this.afterDiscount = this.roundNumberPipe.transform((+this.discountAmount * 100 / +this.discount) - +this.discountAmount);

      this.formulaImgName1 = '12';
      this.formulaImgName2 = '13';

      this.formulaCalculation1 =
      ' = (' + this.discountAmount + ' * 100) : ' + this.discount + ' = ' + this.beforeDiscount;

      this.formulaCalculation2 =
      ' = ( (' + this.discountAmount + ' * 100) : ' + this.discount + ') - ' + this.discountAmount + ' = ' + this.afterDiscount;

    } else
    if (this.areCalculatedFields('beforeDiscount', 'discount', false) &&
        this.afterDiscount !== '' && this.discountAmount !== '') {
      this.hasSolution = true;

      this.beforeDiscount = this.roundNumberPipe.transform(+this.afterDiscount + +this.discountAmount);
      this.discount = this.roundNumberPipe.transform(+this.discountAmount * 100 / (+this.afterDiscount + +this.discountAmount));

      this.formulaImgName1 = '14';
      this.formulaImgName2 = '15';

      this.formulaCalculation1 =
      ' = ' + this.afterDiscount + ' + ' + this.discountAmount + ' = ' + this.beforeDiscount;

      this.formulaCalculation2 =
      ' = (' + this.discountAmount + ' * 100) : (' + this.afterDiscount + ' + ' + this.discountAmount + ') = ' + this.discount;

    }
  }

  clearInput(input: string) {
    switch (input) {
      case 'beforeDiscount':
        this.beforeDiscount = '';
        break;
      case 'discount':
        this.discount = '';
        break;
      case 'afterDiscount':
        this.afterDiscount = '';
        break;
      case 'discountAmount':
        this.discountAmount = '';
        break;
      default:
        break;
    }
    this.hasSolution = false;
  }

  getFieldLabel(input: string): string {
    switch (input) {
      case 'beforeDiscount':
        return 'Before discount';
      case 'discount':
        return 'Discount';
      case 'afterDiscount':
        return 'After discount';
      case 'discountAmount':
        return 'Discount amount';
      default:
        return '';
    }
  }

}
